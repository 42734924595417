/*
 * Copyright 2018 Red Hat, Inc. and/or its affiliates.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from 'react';
import { useState } from 'react';
import './App.css';
import ConfirmPassword from './components/ConfirmPassword';
import Header from './Header';
import Main from './Main';
import { ToastContainer } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';

function App() {
    const Messages = {
        INACTIVE: 1,
        ERROR: 2}

  const [isAuthenticated, setAuthenticated] = useState(false);
  const serverMessage = localStorage.getItem('vehicle_router_status');
  const [showToast, setShowToast] = useState(serverMessage==='timedout' || serverMessage==='error');
  const [toastType, setToastType] = useState(serverMessage==='timedout' ? Messages.INACTIVE : Messages.ERROR)
  

  const toastMessage = (<ToastContainer className="p-3" position="top-end" style={{zIndex:'1200'}}>
    <Toast onClose={() => setShowToast(false)} show={showToast} delay={10000} autohide bg={toastType===Messages.INACTIVE?'warning':'danger'}>
      <Toast.Header>
        <strong className="me-auto">Server Message</strong>
        <small className="text-muted">just now</small>
      </Toast.Header>
      <Toast.Body>{toastType===Messages.INACTIVE? 'Your session timed out due to inactivity or a server restart.' : 'Sorry, an error occurred.'}</Toast.Body>
    </Toast>
    </ToastContainer>);

    return (
      <>
        {console.log("v2 referrer: " + document.referrer)}
        {showToast&&!isAuthenticated?toastMessage:null}
        {localStorage.removeItem('vehicle_router_status')}
        {isAuthenticated ? <><Header /><Main /></> : null}
        {(document.referrer.includes("secure.ptimobilelab.com")||document.referrer.includes("secure.ptihealth.com")||document.location.hostname === "localhost"||(serverMessage==='timedout' || serverMessage==='error'))?<ConfirmPassword setAuthenticated={setAuthenticated} />:null}
      </>
    );

    

  }

export default App;

export const versionHistory = 
"<p class='alertp'><b>V.20220718</b> - initial launch" + 
"<p class='alertp'><b>V.20220822</b> - added time windowing, service times, zipcode preferences, editing of clients/drivers, flagging of import problems, additional daily plans" + 
"<p class='alertp'><b>V.20220926</b> - added multiuser capability, sql database connectivity, plan logging" +
"<p class='alertp'><b>V.20221116</b> - improved security, map updated for CT, DE, MD, NJ, NY, PA" +
"<p class='alertp'><b>V.20230104</b> - added autogenerated routes to determine schedule availability, allow checking for address changes, map marker updates";
